import { Link } from "gatsby";
import React from "react";

export function Radio({ title, isSelected, onChange, value, to, icon, ref }) {
  return (
    <Link className="animation-stagger" ref={ref} to={to}>
      <label className="custom-radio">
        <input
          type="radio"
          name="radio"
          value={value}
          checked={isSelected}
          onChange={onChange}
        />
        <div className="radio-btn">
          {icon}
          <h3>{title}</h3>
        </div>
      </label>
    </Link>
  );
}
