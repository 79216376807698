import { Link } from "gatsby";
import React from "react";
import DarkLogo from "../../../images/Darklogo.svg";
import "../../../styles/index.scss";

export function QuoteLayout({ children }) {
  return (
    <main className="quote-main">
      <nav className="quote-navigation">
        <Link to="/">
          <img
            className="quote-logo"
            quote-logo
            alt="Digital Comparison"
            src={DarkLogo}
          />
        </Link>
      </nav>
      {children}
    </main>
  );
}
