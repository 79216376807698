import { Link } from "gatsby";
//import React from "react";
import React, { useEffect } from "react";
import Confirmation from "../../../svgs/misc/Confirmation";
import { QuestionSection } from "./QuestionSection";
import "../../../styles/index.scss";
import { QueHeader, QueLayout } from "../../../question_components/container";
export function Thankyou({ FaqData, title }) {
  useEffect(() => {
    // Trigger the Google Ads conversion tracking event
    window.gtag('event', 'conversion', {'send_to': 'AW-16556389763/LWKACP2Uyq0ZEIPr2dY9'});
  }, []);
  return (    
    <QueLayout FaqData={FaqData}>
    <div class="thankyou-page">
      <QueHeader
        title={title}
        progress={100}
        question=""
      />
      </div>
      <div className="thanks-content">
        <div className="thanks-content-wrapper">
        <div class="que-wrapper"><h1 class="que-text">Thank You For Your Request. We Will Be In Touch!</h1></div>
          <Confirmation />
          <p>
            1. One of our experts will review your requirement. If they have any
            questions, they might call or email you to clarify anything
            further. This is to ensure we arrange quotes from the most relevent
            suppliers to your needs.
          </p>
          <p>
            2. You recieve quotes: The suppliers that were selected in our
            review step will now provide you personalised quotes directly.
          </p>
          <p>if you have any questions or concerns, please email</p>
          <Link href="mailto:info@digitalcomparison.com.au">
            info@digitalcomparison.com.au
          </Link>
        </div>
      </div>
    </QueLayout>
  );
}
