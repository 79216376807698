import React from "react";

export default function Confirmation() {
  return (
    <svg
      width="773"
      height="773"
      viewBox="0 0 773 773"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame">
        <g id="Group">
          <path
            id="Vector"
            d="M767.456 240.359L684.284 172.361V46.2248C684.284 20.7357 663.548 0 638.053 0H516.34C508.001 0 501.242 6.75856 501.242 15.0977C501.242 23.4368 508.001 30.1953 516.34 30.1953H638.053C646.893 30.1953 654.088 37.3903 654.088 46.2248V314.196L455.949 464.843L257.81 314.196V179.585C257.81 179.585 257.81 179.58 257.81 179.574V46.2248C257.81 37.3903 265.005 30.1953 273.846 30.1953H395.559C403.898 30.1953 410.656 23.4368 410.656 15.0977C410.656 6.75856 403.898 0 395.559 0H273.846C248.351 0 227.615 20.7357 227.615 46.2248V172.361L144.442 240.359C140.933 243.226 138.898 247.519 138.898 252.048V419.662H70.959C62.6199 419.662 55.8613 426.42 55.8613 434.759C55.8613 443.093 62.6199 449.857 70.959 449.857H138.898V605.994C138.898 631.483 159.634 652.219 185.123 652.219H726.775C752.264 652.219 773 631.483 773 605.994V252.048C773 247.519 770.965 243.226 767.456 240.359V240.359ZM742.805 599.129L564.169 420.493L742.805 284.679V599.129ZM684.284 211.361L734.908 252.75L684.284 291.237V211.361ZM455.949 498.907C459.169 498.907 462.389 497.881 465.09 495.828L539.912 438.941L722.995 622.023H188.904L371.986 438.941L446.814 495.828C449.509 497.881 452.729 498.907 455.949 498.907ZM169.094 284.679L347.73 420.493L169.094 599.129V284.679ZM227.615 291.237L176.991 252.75L227.615 211.361V291.237Z"
            fill="#242424"
          />
          <path
            id="Vector_2"
            d="M96.625 516.287H15.0977C6.75856 516.287 0 523.045 0 531.384C0 539.717 6.75856 546.482 15.0977 546.482H96.625C104.964 546.482 111.723 539.717 111.723 531.384C111.723 523.045 104.964 516.287 96.625 516.287Z"
            fill="#242424"
          />
          <path
            id="Vector_3"
            d="M455.949 30.1953C459.918 30.1953 463.817 28.5794 466.624 25.7722C469.431 22.965 471.047 19.0667 471.047 15.0977C471.047 11.1286 469.431 7.23036 466.624 4.42314C463.817 1.61592 459.918 0 455.949 0C451.98 0 448.082 1.61592 445.275 4.42314C442.467 7.23036 440.852 11.1286 440.852 15.0977C440.852 19.0667 442.467 22.965 445.275 25.7722C448.082 28.5794 451.98 30.1953 455.949 30.1953Z"
            fill="#242424"
          />
          <path
            id="Vector_4"
            d="M333.458 218.904C333.458 286.448 388.405 341.402 455.949 341.402C523.494 341.402 578.441 286.448 578.441 218.904C578.441 151.36 523.494 96.4126 455.949 96.4126C388.405 96.4126 333.458 151.36 333.458 218.904ZM548.245 218.904C548.245 269.8 506.845 311.206 455.949 311.206C405.054 311.206 363.653 269.8 363.653 218.904C363.653 168.009 405.054 126.608 455.949 126.608C506.845 126.608 548.245 168.014 548.245 218.904Z"
            fill="#242424"
          />
          <path
            id="Vector_5"
            d="M432.43 204.962C426.532 199.065 416.978 199.065 411.081 204.962C405.183 210.854 405.183 220.414 411.081 226.311L437.732 252.962C440.681 255.911 444.543 257.386 448.406 257.386C452.269 257.386 456.132 255.911 459.081 252.962L505.848 206.195C511.74 200.303 511.74 190.744 505.848 184.846C499.951 178.948 490.391 178.948 484.493 184.846L448.406 220.933L432.43 204.962Z"
            fill="#242424"
          />
        </g>
      </g>
    </svg>
  );
}
