import { Link } from "gatsby";
import React from "react";
import { Product_Routes } from "../../../data/Routes";
import Protected from "../../../svgs/privacy.svg";

export function PrivacySection() {
  return (
    <section className="policy-section">
      <div className="policy-wrapper">
        <img className="privacy-icon" src={Protected} alt="privacy-protected" />

        <p className="privacy-paragraph">
          By Clicking Submit, you agree to the Digital Comparision
          <Link to={Product_Routes.TermandCondition}>Terms of Service</Link>
          and <Link to={Product_Routes.PrivacyPolicy}> Privacy Policy.</Link>
          Consent is not a condition of purchasing any products or services.
        </p>
      </div>
    </section>
  );
}
