import React from "react";
import { Briefcase, Mail, MapPin, Phone, User } from "react-feather";
import styled from "styled-components";

import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  Label,
  Input,
  StyledInlineErrorMessage,
  Submit,
  InputCover,
} from "./styles";

export function CustomerDetails({ onSubmit }) {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const YupSchema = Yup.object().shape({
    fullname: Yup.string()
      .min(2, "Your name is too short")
      .required("Please enter your full name"),
    companyname: Yup.string()
      .min(2, "Company name is too short")
      .required("Please enter your company name"),
    phonenumber: Yup.string()
      .required("required")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),
    postcode: Yup.string()
      .min(4, "Must be exactly 4 digits")
      .max(4, "Must be exactly 4 digits")
      .required("Please enter your postcode"),
    email: Yup.string()
      .email("The email is incorrect")
      .required("Please enter your email"),
  });

  return (
    <Formik
      initialValues={{
        fullname: "",
        email: "",
        companyname: "",
        phonenumber: "",
        postcode: "",
      }}
      validationSchema={YupSchema}
      //onSubmit={onSubmit}
      onSubmit={(values, { setSubmitting }) => {
        // Trigger the Google Ads conversion tracking event
        window.gtag('event', 'conversion', {
          'send_to': 'AW-16556389764/LWKACP2Uyq0ZeIPr5dY9'
        });

        // Call the passed onSubmit function
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        isSubmitting,
        isValidating,
        isValid,
      }) => {
        return (
          <>
            <Form
              className="form-customer"
              name="contact"
              method="post"
              onSubmit={handleSubmit}
            >
              <Label htmlFor="fullname">
                <InputCover>
                  <Name />
                  <Input
                    type="text"
                    name="fullname"
                    autoCorrect="off"
                    autoComplete="name"
                    placeholder="Your Name"
                    valid={touched.fullname && !errors.fullname}
                    error={touched.fullname && errors.fullname}
                  />
                </InputCover>
              </Label>
              {errors.fullname && touched.fullname && (
                <StyledInlineErrorMessage>
                  {errors.fullname}
                </StyledInlineErrorMessage>
              )}
              <Label htmlFor="email">
                <InputCover>
                  <Email />
                  <Input
                    type="email"
                    name="email"
                    autoCapitalize="off"
                    autoCorrect="off"
                    autoComplete="email"
                    placeholder=" Company Email Address"
                    valid={touched.email && !errors.email}
                    error={touched.email && errors.email}
                  />
                </InputCover>
              </Label>
              <ErrorMessage name="email">
                {(msg) => (
                  <StyledInlineErrorMessage>{msg}</StyledInlineErrorMessage>
                )}
              </ErrorMessage>
              <Label htmlFor="companyname">
                <InputCover>
                  <Company />
                  <Input
                    type="text"
                    name="companyname"
                    autoCorrect="off"
                    autoComplete="companyname"
                    placeholder="Company Name"
                    valid={touched.companyname && !errors.companyname}
                    error={touched.companyname && errors.companyname}
                  />
                </InputCover>
              </Label>
              {errors.companyname && touched.companyname && (
                <StyledInlineErrorMessage>
                  {errors.companyname}
                </StyledInlineErrorMessage>
              )}
              <Label htmlFor="phonenumber">
                <InputCover>
                  <Phonenumber />
                  <Input
                    max="10"
                    type="tel"
                    name="phonenumber"
                    autoCorrect="off"
                    autoComplete="name"
                    placeholder="Phone Number"
                    valid={touched.phonenumber && !errors.phonenumber}
                    error={touched.phonenumber && errors.phonenumber}
                  />
                </InputCover>
              </Label>
              {errors.phonenumber && touched.phonenumber && (
                <StyledInlineErrorMessage>
                  {errors.phonenumber}
                </StyledInlineErrorMessage>
              )}
              <Label htmlFor="postcode">
                <InputCover>
                  <Postcode />
                  <Input
                    type="tel"
                    name="postcode"
                    autoCorrect="off"
                    autoComplete="name"
                    placeholder="Postcode"
                    valid={touched.postcode && !errors.postcode}
                    error={touched.postcode && errors.postcode}
                  />
                </InputCover>
              </Label>
              {errors.postcode && touched.postcode && (
                <StyledInlineErrorMessage>
                  {errors.postcode}
                </StyledInlineErrorMessage>
              )}
              <Submit type="submit" disabled={!isValid || isSubmitting}>
                {isSubmitting ? `Submiting...` : `Get A Free Quote`}
              </Submit>
            </Form>
          </>
        );
      }}
    </Formik>
  );
}

const Postcode = styled(MapPin)`
  width: 2.5rem;
  height: auto;
  color: #0563ba;
  margin: 0 0.5rem 0 0.5rem;
`;
const Name = styled(User)`
  width: 2.5rem;
  height: auto;
  color: #0563ba;
  margin: 0 0.8rem 0 0.8rem;
`;
const Email = styled(Mail)`
  width: 2.5rem;
  height: auto;
  color: #0563ba;
  margin: 0 0.8rem 0 0.8rem;
`;
const Company = styled(Briefcase)`
  width: 2.5rem;
  height: auto;
  color: #0563ba;
  margin: 0 0.8rem 0 0.8rem;
`;
const Phonenumber = styled(Phone)`
  width: 2.5rem;
  height: auto;
  color: #0563ba;
  margin: 0 0.8rem 0 0.8rem;
`;
