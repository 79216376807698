import React from "react";
import { QuoteHeader } from "./QuoteHeader";
import "../../../styles/index.scss";

export function QuestionSection({
  children,
  question,
  path,
  progress,
  icon,
  title,
  className,
}) {
  return (
    <div className="main-wrapper" path={path}>
      <QuoteHeader icon={icon} title={title} progres={progress} />
      <section className="question-section">
        <div className="quote-question">
          <h1>{question}</h1>
        </div>
        <div className="question-wrapper" className={className}>
          {children}
        </div>
      </section>
    </div>
  );
}
