import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import "../../../styles/index.scss";

export function QuoteHeader({ icon, progres, title }) {
  return (
    <header className="quote-header">
      <div className="quote-product-name">
        {icon} <h2>{title}</h2>
      </div>
      <div className="quote-progressbar">
        <ProgressBar
          className="progress-bar"
          bgColor="rgba(5, 99, 186, 1)"
          completed={progres}
          height={28}
          labelAlignment="center"
          transitionTimingFunction="linear"
        />
      </div>
    </header>
  );
}
