import { createContext } from "react";
const initialstate = {
  que1: "",
  que2: "",
  que3: "",
  que4: "",
};
const HardwareContext = createContext(initialstate);
HardwareContext.displayName = "HardwareContext";

export default HardwareContext;
